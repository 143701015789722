import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { loadStripe } from "@stripe/stripe-js"


import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const stripePromise = loadStripe("pk_test_51GsZwiGYMUvRP64ZYQcDWEnQv3pXIMtelejj60CUAzjZvF2ROgxLWYmQ246ivJ5q25tQsQBDmqQkc4vobURdEOhF008P8hzulw")
const buttonStyles = {
  fontSize: "13px",
  textAlign: "center",
  color: "#fff",
  outline: "none",
  padding: "12px 60px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
}
const redirectToCheckout = async event => {
  event.preventDefault()
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    lineItems: [
      // Replace with the ID of your price
      { price: 'price_1Gsb27GYMUvRP64ZCM3u8YSJ', quantity: 1 }
    ],
    mode: 'payment',
    successUrl: `http://localhost:8000/page-2/`,
    cancelUrl: `/`,
  })
  if (error) {
    console.warn("Error:", error)
  }
}


const PayPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  console.log(data)
  return (
    <Layout title={siteTitle} location={data.location}>
      <SEO title="Pay Louise online for your lesson" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Thank you
          </h2>
          <p style={{textAlign:`center`}}>If possible let me know you paid online. Hopefully you have already booked in again but if not but if not and you would like to please call or text <a href="tel:07917341804">07917 341804</a> when you're ready.</p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <PayPage location={props.location} data={data} {...props} />
    )}
  />
)
